import { FilledButton } from '@jsluna/button'
import { Container } from '@jsluna/grid'

function CallToAction({ action, text }: any) {
  return <FilledButton onClick={action}>{text}</FilledButton>
}

function Error({ message, action, text }: any) {
  return (
    <>
      <Container className="a-page-push ln-u-text-align-center">
        <p>{message}</p>
      </Container>
      <Container className="ln-u-text-align-center">
        {action && <CallToAction action={action} text={text} />}
      </Container>
    </>
  )
}

export default Error
