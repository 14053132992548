import { Time, ErrorCircle } from '@jsluna/icons'
import { GridItem } from '@jsluna/grid'
import {
  ToggleButtonGroup,
  ToggleButton
} from '@jsainsburyplc/jsluna-toggle-button'
import { useState } from 'react'
import FireAlarmConfirmationModals from './FireAlarmConfirmationModals'

interface FireAlarmToggleProps {
  fireAlarmOnTest: boolean | undefined
  setFireAlarmOnTest: any
  loading: boolean
}

const FireAlarmToggle = ({ loading, fireAlarmOnTest, setFireAlarmOnTest }: FireAlarmToggleProps) => {
  const buttons = [
    { label: 'Live', value: false },
    { label: 'Test mode', value: true }
  ]
  
  const [isOpen, setIsOpen] = useState(false)
  
  return (
    <>
      <GridItem size="1/1">
        <ToggleButtonGroup
          value={fireAlarmOnTest}
          onChange={(value: boolean) => {
            if (fireAlarmOnTest !== value) {
              setIsOpen(true)
            }
          }}
        >
          {buttons.map(button => (
            <ToggleButton 
              key={button.value}
              value={button.value}>
              {button.label !== 'Live' ? (
                <Time className="ln-u-margin-right" size="medium" />
              ) : (
                <ErrorCircle className="ln-u-margin-right" size="medium" />
              )}
              {button.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </GridItem>
      <FireAlarmConfirmationModals
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        fireAlarmOnTest={fireAlarmOnTest}
        setFireAlarmOnTest={setFireAlarmOnTest}
        loading={loading}
      />
    </>
  )
}

export default FireAlarmToggle
