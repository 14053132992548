import { useEffect } from 'react'
import { Container } from '@jsluna/react'
import useIncidentLoader from '../../hooks/useIncidentLoader'
import { useNavigate, useParams } from 'react-router'
import Error from '../Error'
import Loading from '../Loading'
import BackButton from '../BackButton'
import IssueSummary from './IssueSummary/IssueSummary'
import IssueHistory from './IssueHistory/IssueHistory'
import Caller from './Caller/Caller'

const Issue = () => {
  const { issueNumber } = useParams()

  const navigate = useNavigate();
  let data = null
  const [incidentResource, loadIncident] = useIncidentLoader()

  useEffect(() => {
    loadIncident.current(issueNumber!)
  }, [loadIncident])

  if (incidentResource.hasError || incidentResource?.data === null)
    return (
      <Error
        message="An error has occurred. "
        action={() => navigate(`/issue/${issueNumber}`)}
        text="Try again"
      />
    )

  if (!incidentResource.hasLoaded && !incidentResource.hasError)
    return <Loading message="Loading incident" />

  if (incidentResource.hasLoaded && incidentResource.data) {
    data = incidentResource.data
  }
  if (data)
    return (
      <>
        <Container size="xs" className="a-page-push">
          <BackButton previousPage="/tracking" />
        </Container>
        <Container size="xs" className="a-page-push">
          <div className="a-issue-view">
            <IssueSummary incidentDetails={data!} />
            <IssueHistory issueHistory={data!.comments} />
            <Caller callerDetails={data!} />
          </div>
        </Container>
      </>
    )
}

export default Issue
