import { Container } from '@jsluna/react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useCurrentStore } from '../hooks'
import Loading from '../components/Loading'
import { useFireAlarmDetails } from '../hooks/useFireAlarmDetails'
import { IFireAlarmDetails } from '../types/IFireAlarmDetails'
import { useApiClient } from '../context/AppContext'
import fireAlarmApi from '../api/fireAlarmApi'
import Error from '../components/Error'
import { Link, useNavigate } from 'react-router-dom'
import { Card } from '@jsluna/card'
import { ArrowLeft } from '@jsluna/icons'
import { IconButton } from '@jsluna/button'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Display2, Body1 } from '@jsluna/typography'
import FireAlarmTestModeDetails from '../components/FireAlarm/FireAlarmBanners/FireAlarmTestInformation'
import { Label } from '@jsluna/form'
import FireAlarmTestWarning from '../components/FireAlarm/FireAlarmBanners/FireAlarmTestWarning'
import FireAlarmStatusHistory from '../components/FireAlarm/FireAlarmStatusHistory'
import FireAlarmToggle from '../components/FireAlarm/FireAlarmToggle'
import StoreContactInformation from '../components/FireAlarm/StoreContactInformation'
import { IFireAlarmErrorResponse } from '../types/IFireAlarmErrorResponse'
moment.locale('en_GB')

const FireAlarmPage = () => {
  const navigate = useNavigate()
  const apiClient = useApiClient()
  const currentStore = useCurrentStore()
  const [fireAlarmDetails, setFireAlarmDetails] = useState<
    IFireAlarmDetails | undefined
  >(undefined)
  const [alarmDetailsResource, alarmDetailsLoader] = useFireAlarmDetails()
  const [error, setError] = useState<IFireAlarmErrorResponse>({
    isError: false,
    statusCode: 0
  })
  const [loading, setLoading] = useState<boolean>(false)

   if (document.referrer === '')  {
     return <Error message="Please return to the assist directory and access fire alarm testing from there" action={() => navigate('/')} text="Home" />
   }

  useEffect(() => {
    alarmDetailsLoader.current(currentStore.storeCode)
  }, [alarmDetailsLoader])

  useEffect(() => {
    if (alarmDetailsResource.hasLoaded) {
      setFireAlarmDetails(alarmDetailsResource.data)
    }
  }, [alarmDetailsResource])

  const setFireAlarmState = async (onTest: boolean) => {
    setLoading(true)
    const response =
      onTest === true
        ? await fireAlarmApi.putFireAlarmOnTest(
            apiClient,
            currentStore.storeCode
          )
        : await fireAlarmApi.putFireAlarmOffTest(
            apiClient,
            currentStore.storeCode
          )

    setLoading(false)
    if (response.isSuccess != null && response.isSuccess) {
      alarmDetailsLoader.current(currentStore.storeCode)
    } else {
      return setError({ isError: true, statusCode: response.statusCode })
    }
  }

  if (!alarmDetailsResource.hasLoaded && !alarmDetailsResource.hasError) 
    return <Loading message={'Loading fire alarm testing details'} />

  if (alarmDetailsResource.hasError)
    return (
      <Error
        className="ln-u-align-items-center ln-u-height-full-vh"
        message={
          'Fire alarm details not available for ' +
          (currentStore.storeName ?? currentStore.storeCode)
        }
        action={() => navigate('/')}
        text="Home"
      />
    )

  if (error.isError && error.statusCode === 403) {
    return (
      <Container className="a-page-push">
        <Error
          message={`You do not have permissions to perform this action`}
          action={() => navigate('/')}
          text="Home"
        />
      </Container>
    )
  } else if (error.isError) {
    return (
      <Container className="a-page-push">
        <Error
          message={`Failed to put fire alarm ${alarmDetailsResource.data?.isOnTest ? 'off' : 'on'} test`}
          action={() => window.location.reload()}
          text="Try again"
        />
      </Container>
    )
  }

  return (
    <>
      <Container className="ln-u-padding-ends*4" size="xs">
        <Link to="/">
          <IconButton
            className="ln-u-padding-ends*1"
            color="dark"
            variant="text"
            label="Back"
          >
            <ArrowLeft size="small" />
          </IconButton>
        </Link>
        <Card>
          <GridWrapper matrix>
            <StoreContactInformation currentStore={currentStore} />
            <GridItem size="1/1">
              <Display2>Fire alarm</Display2>
              <div>
                <Label htmlFor="">
                  Current status of your fire alarm in store
                </Label>
              </div>
              <Body1>
                The fire alarm should always be live, unless it is being tested
                or fixed.
              </Body1>
            </GridItem>
            {fireAlarmDetails && <FireAlarmToggle loading={loading} fireAlarmOnTest={fireAlarmDetails?.isOnTest} setFireAlarmOnTest={setFireAlarmState} />}
            <GridItem size="1/1">
              <Body1>
                The fire alarm will be put in &apos;test mode&apos; for 30
                minutes, to allow you to conduct the fire alarm tests in store
                using the fire panel, as part of the periodic risk checks. The
                change of status of the fire alarm is recorded and continually
                monitored.
              </Body1>
            </GridItem>
              {fireAlarmDetails && fireAlarmDetails?.events && fireAlarmDetails.events.length > 0 && moment().diff(moment(fireAlarmDetails.events[0].recordedAt), 'weeks') > 4 && <FireAlarmTestWarning />}
              {fireAlarmDetails && fireAlarmDetails?.isOnTest === true && fireAlarmDetails.events.length > 0 && <FireAlarmTestModeDetails event={fireAlarmDetails.events[0]} />}
              <FireAlarmStatusHistory history={fireAlarmDetails?.events} />
          </GridWrapper>
        </Card>
      </Container>
    </>
  )
}

export default FireAlarmPage
