import { Routes, Route, useLocation } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { Container, Modal, SiteLayout, Footer } from '@jsluna/react'

import { AppContext } from './context/AppContext'

import { InteractionType } from '@azure/msal-browser'

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication
} from '@azure/msal-react'

import './scss/main.scss'
import NavHeader from './components/Header/NavHeader'
import useCurrentStore from './hooks/useCurrentStore'
import { IStore } from './types/IStore'
import StorePage from './pages/StorePage'
import Loading from './components/Loading'
import { useStoreLoader } from './hooks'
import SwitchBoardPage from './pages/SwitchBoard'
import SwitchboardEntry from './components/SwitchboardEntry/SwitchboardEntry'
import TrackingPage from './pages/Tracking'
import Issue from './components/Issue/Issue'
import Logging from './components/Logging/logging'
import FireAlarmPage from './pages/FireAlarmPage'


const App = () => {
  const context = useContext(AppContext)

  useMsalAuthentication(InteractionType.Redirect, {
    scopes: [context?.apiScope ?? '']
  })

  const location = useLocation()
  useEffect(() => {
    if (
      context?.store !== undefined &&
      store.storeCode !== context!.store!.storeCode
    )
      setStore(context!.store!)
  }, [location])

  const currentStore = useCurrentStore()

  const [store, setStore] = useState(currentStore)
  const [storeResource, loadStore] = useStoreLoader()

  useEffect(() => {
    loadStore.current(currentStore.storeCode)
  }, [context, currentStore.storeCode, loadStore])

  if (context && storeResource.data && context.store === undefined) {
    const storeDetails = storeResource.data
    const store: IStore = {
      storeCode: storeDetails.code,
      storeName: storeDetails.name,
      contact: storeDetails.contact,
      inScope: storeDetails ? true : false
    }
    context.store = store
    setStore(store)
  }

  if (!storeResource.hasLoaded && !storeResource.hasError) return <Loading />


  if ((context && context.store !== undefined && !context.store.inScope) || storeResource.hasError) {
    return (
      <AuthenticatedTemplate>
        <SiteLayout>
          <NavHeader currentStore={store}></NavHeader>
          <Container size="md" soft>
              <Routes>
                <Route path="*" element={<StorePage />} />
              </Routes>
          </Container>
        </SiteLayout>
      </AuthenticatedTemplate>
    )
  }

  if (currentStore.storeCode == '') return <Loading message='Please wait' />

  return (
    <>
      <AuthenticatedTemplate>
      <NavHeader currentStore={store}></NavHeader>
        <SiteLayout>
              <Routes>
                <Route
                  path="/"
                  element={<SwitchBoardPage />}
                />
                <Route path="/stores" element={<StorePage />} />
                <Route
                  path="/switchboard/:id"
                  element={<SwitchboardEntry />}
                />

                <Route path="/tracking" element={<TrackingPage />} />
                <Route path="/issue/:issueNumber" element={<Issue />} />
                <Route path="/logging" element={<Logging />} />
                <Route path="/fire-alarm" element={<FireAlarmPage />} />
              </Routes>
          <Footer>&copy; Sainsbury&apos;s Supermarkets Ltd</Footer>

        </SiteLayout>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Modal restrictClose open={true}>
          <h4> Redirecting.. </h4>
        </Modal>
      </UnauthenticatedTemplate>
    </>
  )

}

export default App
