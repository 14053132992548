import { GridItem } from '@jsluna/grid'
import { Body1} from '@jsluna/typography'
import { Label } from '@jsluna/form'
import { ErrorCircle } from '@jsluna/icons'

const FireAlarmTestWarning = () => {
  return (
    <>
      <div className='fa-warning-banner'>
        <GridItem className='ln-u-padding-sides'>
          <Label className='ln-u-flush-bottom fa-banner-label' htmlFor=''>Fire alarm test needed <ErrorCircle className="ln-u-margin-right fa-banner-error-circle" size="medium" /></Label>
        </GridItem>
        <GridItem className='ln-u-padding-sides'>
          <Body1 htmlFor=''>A fire alarm test hasn&apos;t been done in the last 4 weeks and needs to be completed as part of the periodic risk checks.</Body1>
        </GridItem>
      </div>
    </>
  )
}

export default FireAlarmTestWarning
