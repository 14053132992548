import { Time, ErrorCircle, InfoCircle } from '@jsluna/icons'
import { GridItem } from '@jsluna/grid'
import { Modal } from '@jsluna/modal'
import { FilledButton, OutlinedButton } from '@jsluna/button'
import { Body1, Display3 } from '@jsluna/typography'
import Loading from '../Loading'

interface FireAlarmConfirmationModalProps {
  isOpen: boolean
  setIsOpen: any
  fireAlarmOnTest: boolean | undefined
  setFireAlarmOnTest: any
  loading: boolean
}

const FireAlarmConfirmationModals = ({ loading, isOpen, setIsOpen, fireAlarmOnTest, setFireAlarmOnTest }: FireAlarmConfirmationModalProps) => {
  return (
    <>
      <Modal
        small
        alert
        handleClose={() => setIsOpen(false)}
        open={isOpen}
        headingId="dialog-modal"
      >
        <GridItem className="ln-u-text-align-center">
          <InfoCircle className="ln-u-color-blue ln-u-display-7 ln-u-push-bottom" />
        </GridItem>
        <GridItem className="ln-u-hard ln-u-text-align-center">
          <Display3 element="h3">Change the fire alarm mode</Display3>
        </GridItem>
        <GridItem className="ln-u-hard ln-u-text-align-center">
          <Body1>Are you sure you want to change the fire alarm mode?</Body1>
        </GridItem>
        {loading ? (
          <GridItem className="ln-u-text-align-center">
            <Loading />
          </GridItem>
        ) : (
          <>
            {fireAlarmOnTest === false && (
              <>
                <GridItem className="ln-u-margin-ends*2 ln-u-padding-sides ln-u-text-align-center">
                  <FilledButton
                    fullWidth
                    onClick={() => {
                      setFireAlarmOnTest(true)
                    }}
                  >
                    <Time className="ln-u-margin-right" size="medium" />
                    Change to test mode
                  </FilledButton>
                </GridItem>
                <GridItem className="ln-u-padding-sides ln-u-text-align-center">
                  <OutlinedButton fullWidth onClick={() => setIsOpen(false)}>
                    <ErrorCircle className="ln-u-margin-right" size="medium" />
                    Leave live
                  </OutlinedButton>
                </GridItem>
              </>
            )}
            {fireAlarmOnTest === true && (
              <>
                <GridItem className="ln-u-margin-ends*2 ln-u-padding-sides ln-u-text-align-center">
                  <OutlinedButton
                    fullWidth
                    onClick={() => {
                      setFireAlarmOnTest(false)
                    }}
                  >
                    <Time className="ln-u-margin-right" size="medium" />
                    Change to live mode
                  </OutlinedButton>
                </GridItem>
                <GridItem className="ln-u-padding-sides ln-u-text-align-center">
                  <FilledButton fullWidth onClick={() => setIsOpen(false)}>
                    <ErrorCircle className="ln-u-margin-right" size="medium" />
                    Keep in test mode
                  </FilledButton>
                </GridItem>
              </>
            )}
          </>
        )}
      </Modal>
    </>
  )
}

export default FireAlarmConfirmationModals
