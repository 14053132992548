import { IStore } from '../../types/IStore'
import { Display2 } from '@jsluna/typography'
import { GridItem } from '@jsluna/grid'
import storeImage from '../../assets/images/storeLogo.png'

interface StoreContactProps {
  currentStore: IStore
}

const StoreContactInformation = (currentStore: StoreContactProps) => {

  return (
    <>
            <GridItem size="2/4">
              <Display2>
                {' '}
                {currentStore.currentStore.storeName} (
                {currentStore.currentStore.storeCode})
              </Display2>
              <div> {currentStore.currentStore?.contact?.address1 ? currentStore.currentStore?.contact?.address1: null}</div>
              <div> {currentStore.currentStore?.contact?.address2 ? currentStore.currentStore?.contact?.address2: null}</div>
              <div> {currentStore.currentStore?.contact?.address1 ? currentStore.currentStore?.contact?.city: null}</div>
              <div> {currentStore.currentStore?.contact?.address1 ? currentStore.currentStore?.contact?.post_code: null}</div>
            </GridItem>
            <GridItem size="2/4">
              <img src={storeImage} alt="Drawing of a sainsbury's store" />
            </GridItem>
    </>
  )
}

export default StoreContactInformation
