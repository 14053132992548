import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'

const Loading = (message: any) => (
  <div className="a-page-push ln-u-text-align-center ln-u-vertical-align-center">
    <ProgressIndicator
      className="ln-u-justify-content-center"
      loading
      preventFocus
    >
      <p className="ln-u-justify-content-center loading-mesage">
        <ProgressSpinner size="medium" className="ln-u-push-right-sm" />
        {message.message}
      </p>
    </ProgressIndicator>
  </div>
)

export default Loading
