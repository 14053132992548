import { GridItem } from '@jsluna/grid'
import { Body1 } from '@jsluna/typography'
import { Label } from '@jsluna/form'
import { Table } from '@jsainsburyplc/jsluna-table'
import moment from 'moment'
import { IAlarmEvent } from '../../types/IFireAlarmDetails'
import { Container } from '@jsluna/grid'
moment.locale('en_GB')

interface FireAlarmStatusHistoryProps {
  history: IAlarmEvent[] | undefined
}

const FireAlarmStatusHistory = ({ history }: FireAlarmStatusHistoryProps) => {
  return (
    <>
      <GridItem size="1/1">
        <Label htmlFor="">Previous fire alarm status changes</Label>
        <Container>
          <Body1>Showing the last 6 status changes. Last updated {moment().format('HH:mm [today]')}</Body1>
        </Container>
        <br />
      </GridItem>
      { history && history.length > 0 ? (
        <GridItem size="1/1">
          <Table
            rowKey="data.key"
            responsive
            data={history}
            columns={[
              {
                name: 'Date and time',
                accessor: (rowData: IAlarmEvent) => ({
                  value: moment(rowData?.recordedAt)
                    .format('ddd DD MMM YYYY [at] HH:mm')
                    .toString()
                }),
                hideLabel: true
              },
              {
                name: 'Change of status',
                accessor: (rowData: IAlarmEvent) => ({
                  value: rowData?.state
                    .toLowerCase()
                    .replace(/\b\w/g, (char) => char.toUpperCase())
                })
              },
              {
                name: 'Who made the change',
                accessor: (rowData: IAlarmEvent) => ({
                  value: rowData?.recordedBy
                    .toLowerCase()
                    .replace(/\b\w/g, (char) => char.toUpperCase())
                })
              }
            ]}
          />
        </GridItem>
      ) : (
        <GridItem size="1/1">
          <Table
            rowKey="data.key"
            responsive
            data={[{ message: 'No status updates available' }]}
            columns={[
              {
                name: '',
                className: 'ln-u-text-align-center',
                accessor: (rowData: { message: string }) => ({
                  value: rowData.message
                }),
                hideLabel: true
              }
            ]}
          />
        </GridItem>
      )}
    </>
  )
}

export default FireAlarmStatusHistory
