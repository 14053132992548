import { useState, useEffect } from 'react'
import { Container, GridWrapper, GridItem } from '@jsluna/grid'
import ReactGA from 'react-ga'
import Error from '../Error'
import ComponentSwitch from './ComponentSwitch'
import getAvailableComponents from './ComponentSwitch/getAvailableComponents'
import LoggingErrorBoundary from './LoggingErrorBoundary'
import { ComponentContextProvider } from './ComponentSwitch/ComponentContext'

import Loading from '../Loading'
import { useCurrentStore } from '../../hooks'
import useLoggingReducer, { back, cancel, next, setStore, updateAnswer } from '../../hooks/useLoggingReducer'
import { useApiClient } from '../../context/AppContext'

const Logging = () =>{
  const store = useCurrentStore()
  const apiClient= useApiClient()
  const [nextDisabledCounter, setNextDisabledCounter] = useState(0)
  const [gaLoggingJourneyTimer, setGaLoggingJourneyTimer] = useState(0)
  const [state, dispatch] = useLoggingReducer(apiClient)

  const [gaSent, setGaSent] = useState(false)

  useEffect(() => {
    if (store.storeCode) {
      dispatch(setStore(store.storeCode))
    }
  }, [store.storeCode])

  useEffect(() => {
    if (state.submitted === true && !gaSent) {
      // Metric is measuring from the point the user visits Assist (including
      // time on other pages). Keeping metric in place to allow comparison while
      // collecting new information on the new metric.
      ReactGA.timing({
        category: 'Assist Trading',
        variable: 'Logging',
        value: Math.round(performance.now()),
      })

      if (gaLoggingJourneyTimer !== 0) {
        ReactGA.timing({
          category: 'Assist Trading',
          variable: 'TimeToLog',
          value: Date.now() - gaLoggingJourneyTimer,
        })
      }
      setGaSent(true)
    }
  })

  useEffect(() => {
    if (gaLoggingJourneyTimer === 0) setGaLoggingJourneyTimer(Date.now())
  }, [])

  const handleUpdate = ({id,value}:{id:any,value:any}) => {
    dispatch(updateAnswer(id , value))
  }
  const handleNext = (overrides: any) => {
    dispatch(next(overrides))
  }
  const handleCancel = () => {
    dispatch(cancel())
  }
  const handleBack = () => {
    dispatch(back())
  }

  const disableNext = () => {
    setNextDisabledCounter(count => count + 1)
  }

  const enableNext = () => {
    setNextDisabledCounter(count => count - 1)
  }

  const isNextDisabled = () => nextDisabledCounter > 0

  useEffect(() => {
    if (state.loading) {
      disableNext()
    } else {
      enableNext()
    }
  }, [state.loading])

  if (!state?.components && state.loading) return <Loading />

  if (state.error) {
    return (
      <Container className="a-page-push">
        <Error
          message={state?.error?.message || 'An error has occurred'}
          action={() => window.location.reload()}
          text="Try again"
        />
      </Container>
    )
  }

  return (
    <Container size="xs" className="a-page-push">
      <LoggingErrorBoundary>
        <ComponentContextProvider
          availableComponents={getAvailableComponents()}
          answers={state.answers || {}}
          handleNext={handleNext}
          handleUpdate={handleUpdate}
          handleCancel={handleCancel}
          handleBack={handleBack}
          isNextDisabled={isNextDisabled}
          enableNext={enableNext}
          disableNext={disableNext}
        >
          <GridWrapper
            matrix
            gutterSize="zero"
            className="ln-o-grid--gutter-zero@max-mobile"
          >
            {state.components?.map((componentProps:any) => (
              <GridItem
                key={`${state.serviceType}-${state.id}-${componentProps.id}`}
                size="1/1"
              >
                <ComponentSwitch
                  {...componentProps}
                  key={`${state.serviceType}-${state.id}`}
                />
              </GridItem>
            ))}
          </GridWrapper>
        </ComponentContextProvider>
      </LoggingErrorBoundary>
    </Container>
  )
}

export default Logging
