import { IFireAlarmDetails } from '../types/IFireAlarmDetails'
import fireAlarmApi from '../api/fireAlarmApi'
import { useApiClient } from '../context/AppContext'
import useResourceLoader from './useResourceLoader'
import { FetchFireAlarmDetails } from '../types/FetchFireAlarmDetails'
import useCallbackRef from './useCallbackRef'
import IResourceLoader from '../types/IResourceLoader'
import { ILoadFireAlarmDetails } from '../types/ILoadFireAlarmDetails'

export const useFireAlarmDetails = (): [
  IResourceLoader<IFireAlarmDetails>,
  ILoadFireAlarmDetails
] => {
  const apiClient = useApiClient()

  const fireAlarmDetailsResource = useResourceLoader<IFireAlarmDetails>('Failed to load fire alarm details')

  const fireAlarmDetailsLoader = useCallbackRef<FetchFireAlarmDetails>((storeCode:string) =>
    fireAlarmDetailsResource.load(async () => fireAlarmApi.getFireAlarmDetails(apiClient, storeCode))
  )

  return [fireAlarmDetailsResource, fireAlarmDetailsLoader]
}