import { IFireAlarmResponse } from '../types/IFireAlarmResponse'
import { IFireAlarmDetails } from '../types/IFireAlarmDetails'
import { ApiClient } from './ApiClient'
import webApi from './webApi'

const getFireAlarmDetails = async (
    apiClient: ApiClient,
    storeCode: string
): Promise<IFireAlarmDetails> => {
  const response = await webApi.get<IFireAlarmDetails>(apiClient, {
    url: `api/alarm/fire`,
    params: { storeCode: storeCode }
  })

  return response;
}

const putFireAlarmOnTest = async (
  apiClient: ApiClient,
  storeCode: string
): Promise<IFireAlarmResponse> => {
  try {
    const response = await webApi.post<IFireAlarmResponse>(apiClient, {
      url: `api/alarm/fire/ontest`,
      params: [],
      body: JSON.stringify(storeCode)
    })

  return response;
  
  } catch (error: any) {
    return { statusCode: error.status, isSuccess: false, message: error.message }
  }
}

const putFireAlarmOffTest = async (
  apiClient: ApiClient,
  storeCode: string
): Promise<IFireAlarmResponse> => {
  try {
    const response = await webApi.post<IFireAlarmResponse>(apiClient, {
      url: `api/alarm/fire/offtest`,
      params: [],
      body: JSON.stringify(storeCode)
    })

  return response;

  } catch (error: any) {
    return { statusCode: error.status, isSuccess: false, message: error.message }
  }
}

const fireAlarmApi = {
  getFireAlarmDetails,
  putFireAlarmOnTest,
  putFireAlarmOffTest
}

export default fireAlarmApi
