import { ErrorCircle } from '@jsluna/icons'
import { GridItem } from '@jsluna/grid'
import { Body1 } from '@jsluna/typography'
import { Label } from '@jsluna/form'
import { IAlarmEvent } from '../../../types/IFireAlarmDetails'
import moment from 'moment';

interface FireAlarmOnTestInformationProps {
  event: IAlarmEvent
}

const FireAlarmTestModeDetails = ({ event }: FireAlarmOnTestInformationProps) => {

  const testModeEndTime = event?.recordedAt ? moment(event.recordedAt).add(30, 'minutes').format('HH:mm') : "unknown";

  return (
    <>
      <div className="fa-information-banner">
        <GridItem className="ln-u-padding-sides">
          <Label htmlFor='' className="ln-u-flush-bottom fa-banner-label">
            Fire alarm is in test
            <ErrorCircle
              className="ln-u-margin-right fa-banner-information-circle"
              size="medium"
            />
          </Label>
        </GridItem>
        <GridItem className="ln-u-padding-sides">
          <Body1>
            The fire alarm will be in &apos;test mode&apos; for 30 minutes and
            will <b>return to live at {testModeEndTime}</b>.
          </Body1>
        </GridItem>
        <GridItem className="ln-u-padding-sides ln-u-padding-top*4">
          <Body1>
            You will now need to complete the following tests in store:
          </Body1>
        </GridItem>
        <GridItem className="ln-u-padding-sides">
          <ul className="fa-information-list">
            <li>
              Proximity infra-red (PIR) controlled doors must be switched to the
              hold open position and ensure sliding doors are unlocked.
            </li>
            <li>
              Switch off the Heating and Ventilation (Disable H&V or TEST H&V)
              Isolation on the fire panel (if applicable).
            </li>
            <li>
              Make a verbal announcement to the colleagues and customers that
              the fire alarms are about to be tested of if available use the
              tannoy.
            </li>
            <li>
              Inform colleague(s) that are equipped with vibrating pagers that
              the alarm is about to be tested.
            </li>
            <li>
              Complete either of the following to ensure that the evacuation
              alarm sounds as these are the only ways that a signal will be sent
              to the ARC confirming that the link is working:
            </li>
            <ul className="fa-information-list">
              <li>
                <b>2 Stage Fire Alarm System</b>
              </li>
              <ul className="fa-information-list">
                <li>
                  Activate 1 yellow break glass call point from around the
                  store.
                </li>
                <li>
                  <b>OR</b> Activate 2 red break glass call points
                  simultaneously.
                </li>
              </ul>
              <li>
                <b>Single Stage System</b>
              </li>
              <ul className="fa-information-list">
                <li>Activate a red break glass call point.</li>
              </ul>
            </ul>
            <li>
              The evacuation signal must sound for at least 10 secounds to send
              a signal to the ARC - Failure to do so may not send the signal to
              the ARC.
            </li>
          </ul>
        </GridItem>
        <GridItem className="ln-u-padding-sides ln-u-padding-top*4">
          <Body1 htmlFor="">For more info see Fire Tests and Checks.</Body1>
        </GridItem>
        <GridItem className="ln-u-padding-sides ln-u-padding-top*4">
          <Body1 htmlFor="">
            You should manually switch the fire alarm back to live, once the
            tests are complete, so that the fire alarm is active and the store
            is not at risk without a fire alarm and then record the results in
            the relevant periodic risk check.
          </Body1>
        </GridItem>
      </div>
    </>
  )
}

export default FireAlarmTestModeDetails
